    // color veriables
    $primary     : #A90007;
    $secondry-red:#e51c1c;
    $primary-text: #000;
    $cetas-blue  :#164569;
    $white       :#fff;
    $seperator   :#E3E3E3;
    $placeholder :#ADADAD;
    $success     :#1FCE39;
    $warning     :#FF7F41;
    $link        :#41A2FF;

    body {

      // headings mentioned
      h2 {
        font-size  : 36px;
       // font-family: 'Roboto', sans-serif;
        font-weight: 400;
      }

      h4 {
        font-size     : 18px;
        letter-spacing: 0;
        line-height   : 19px;
      }

      h5 {
        font-size     : 15px;
        letter-spacing: 0;
        line-height   : 19px;
      }

      //font-family: "Roboto",
      //sans-serif;

      font-size: 14px;

      .wrapper {
        width: 100%;
      }

      .content-wrapper {
        margin: 30px 0;
      }

      // menu component css starts here
      .navbar {
        padding: 0 !important;
      }

      .header-nav {
        background: $white;
        width     : 100%;
        box-shadow: 3px 2px 4px 0px #E6E6E6;

        a.nav-item {
          color: $primary-text;

          &:hover {
            text-decoration: none;
          }

          &:hover {
            border-bottom: solid 5px $primary;
            color        : $primary;
            font-weight  : bold;
          }

          .nav-link {
            padding: 0;
          }
        }

        a.nav-link {
          height     : 73px;
          margin-left: 10px;
          padding-top: 30px;
        }

        a.active {
          border-bottom: solid 5px $primary;
          color        : $primary;
          font-weight  : bold;
        }

        .username-box {
          background: $primary;
          padding   : 30px 18px;
          color     : #fff !important;

          a {
            img {
              margin-right: 10px;
            }
          }
        }

       
        // .css-g1d714-ValueContainer{
        //     height: 20px;
        //   }
        //   .css-g1d714-ValueContainer .css-1uccc91-singleValue {
        //     top: 145%;
        //   }
        //   .css-yk16xz-control {
        //     height: 25px;
        //   }

        //   .css-9ebb9a {
        //     position: relative;
        //     z-index: 9999;
        //     left: 210px;
        //   }
      }

      // menu component css ends here
      .back-section {
        margin-top   : -5px;
        margin-bottom: 10px;

        a {
          color: $link;

          img {
            margin-right: 10px;
          }
        }
      }

      .screen-headingbox {
        padding      : 10px 0;
        border-bottom: solid 1px #E3E3E3;
      }

      .navbar-nav {
         margin-left: 30%;
        //width: 20% !important;
        // text-align: left !important;
        // float: right !important;
        
        
      }

      // side menubar start here
      // side menubar ends here
      .sidebar {
        // min-height: 800px;
        // changed by sanyukta 10-8-2020
        height       : 200px;
        overflow-y   : scroll;
        background   : white;
        margin-bottom: 60px;
        border-right : solid 1px #E3E3E3;
        box-shadow   : 4px 5px 4px 1px #e5e5e578;

        ul.cetas-leftnav {
          list-style-type: none;
          margin         : 0;
          padding        : 0;

          li {
            padding      : 10px;
            background   : $white;
            border-bottom: solid 1px #E3E3E3;
            position     : relative;

            a {
              color      : $primary-text;
              font-size  : 16px;
              font-weight: 400;
              transition : all 0.5s;

              &:hover {
                font-weight    : 600;
                color          : $primary;
                text-decoration: none;
              }
            }

          }

          ul.submenu {
            list-style-type: none;
            padding        : 0;
            margin-top     : 15px;

            .submenu-link {
              &:after {
                content  : '\25B6';
                position : absolute;
                right    : 10px;
                top      : 15px;
                font-size: 12px;
              }
            }
          }

          ul.selected {
            background-color: rgba(217, 83, 79, 0.8);
          }
        }
      }

      // extra added clasees
      .no-border-top,
      .no-border-bottom,
      .no-border-left,
      .no-border-right {
        border-top: none;
      }

      .mt-30 {
        margin-top: 30px;
      }

      .mb-30 {
        margin-bottom: 30px;
      }

      .mt-50 {
        margin-top: 50px;
      }

      a {
        &:hover {
          text-decoration: none;
        }
      }

      .hide {
        display: none;
      }

      .bold {
        font-weight: 700;
      }

      .absolute-right {
        position: absolute;
        right   : 0;
      }

      // form components starts here
      form {
        list-style-type: none;
      }

      input[type="text"] {
        height       : 50px;
        padding      : 10px;
        border       : solid 1px #E3E3E3;
        border-radius: 3px;
        font-size    : 14px;
      }

      input[type=date],
      input[type=datetime-local],
      input[type=month],
      input[type=time] {
        -webkit-appearance: listbox;
        height            : 50px;
        background        : none;
      }

      .form-control:disabled,
      .form-control[readonly] {
        background: none !important;
        opacity   : 1;
        border    : none !important;
        padding   : 0;
        margin    : 0;
        height    : 20px !important;
        border    : none;
      }

      .cetas-button {
        font-size    : 14px !important;
        font-size    : 14px;
        background   : $primary;
        color        : $white;
        padding      : 10px 30px;
        border       : 1px solid #771712;
        border-radius: 3px;
        box-shadow   : 0 2px 4px 0 rgba(119, 119, 119, 0.5);
        transition   : all 0.5s;

        &:hover {
          background: $secondry-red;
        }
      }

      .overall-rating {
        font-size    : 14px !important;
        font-size    : 14px;
        background   : #A90007;
        color        : #fff;
        margin-top   : -30px;
        padding      : 10px;
        border       : 1px solid #771712;
        border-radius: 3px;
        box-shadow   : 0 2px 4px 0 rgba(119, 119, 119, 0.5);
        transition   : all 0.5s;
      }

      .cetas-button-small {
        padding: 3px 10px !important;
      }

      .cetas-secondry-button {
        font-size    : 14px !important;
        font-size    : 14px;
        background   : none;
        color        : $primary;
        padding      : 10px 30px;
        border       : 1px solid $primary;
        border-radius: 3px;
        box-shadow   : 0 2px 4px 0 rgba(119, 119, 119, 0.5);
        transition   : all 0.5s;

        &:hover {
          background: $primary;
          color     : $white;
        }
      }

      .form-rightsec {
        margin-top: 10px;
      }

      .form-rightsec h3 {
        font-size    : 24px;
        font-weight  : 700;
        color        : $primary-text;
        margin-bottom: 30px;
      }

      a.remove-filed {
        font-size  : 28px;
        color      : $primary;
        position   : absolute;
        margin-left: 10px;
      }

      .field-group {
        margin-top: 10px;
      }

      .appended-actions {
        font-size: 12px !important;
      }

      .button-group {
        margin-left: 40px;
        margin-top : 10px;
      }

      .emplist-box {
        margin-top: 30px;
      }

      .table {

        // thead {}
        th {
          border: none;
        }
      }

      .table-bordered {
        th {
          border    : none;
          background: $white;
        }

        td {
          border: none;
        }
      }

      .table-striped {
        tbody {
          tr {
            &:nth-of-type(odd) {
              border    : none;
              background: none;
              border    : solid 1px #EEEEEE;
              margin    : 10px 0;
            }
          }

          p {
            padding-bottom: 0;
          }
        }
      }

      // login part
      // background video component styling starts here
      .bg-video-wrap {
        position: relative;
        overflow: hidden;
        width   : 100%;
        height  : 100vh;

        // background: url(https://designsupply-web.com/samplecontent/vender/codepen/20181014.png) no-repeat center center/cover;
        .slider-info {
          display : inline-block;
          position: absolute;
          top     : 35%;
          width   : 60%;
          left    : 20%;
          z-index : 9;

          h1 {
            color         : $white;
            font-size     : 48px;
            letter-spacing: 0;
            line-height   : 57px;
          }

          h4 {
            color         : $white;
            font-size     : 28px;
            letter-spacing: 0;
            line-height   : 33px;
          }

          p {
            color    : $white;
            font-size: 14px;
          }
        }
      }

      video {
        min-width : 100%;
        min-height: 80vh;
        z-index   : 1;
      }

      .overlay {
        width           : 100%;
        height          : 100vh;
        position        : absolute;
        top             : 0;
        left            : 0;
        background-image: linear-gradient(rgba(0, 0, 0, 0.53) 100%, rgba(0, 0, 0, 0.7) 23%);
        z-index         : 2;
      }

      // footer component css starts here
      // .cetas-footer {
      //   padding   : 25px 0;
      //   box-shadow: none;
      //   border    : none;
      //   height    : 65px;
      //   font-size : 12px;
      //   background: $cetas-blue;
      //   width: 100% !important;
      //   a {
      //     color: $white;
      //   }

      //   p {
      //     color: $seperator
      //   }
      // }

      // .footer-fixed {
      //   position: fixed;
      //   bottom  : 0;
      //   width   : 100%;
      // }

      // modal popup styling
      .cetas-modal {
        .modal-dialog {
          max-width: 500px;
          margin   : 13.75rem auto;

          .modal-content {
            border-radius: 0;
            width        : 457px;
            margin       : 0 auto;

            .modal-body {
              padding: 30px 80px;

              input,
              select {
                border   : solid 1px $seperator;
                font-size: 14px;
                height   : 50px;

                &::placeholder {
                  color    : $placeholder;
                  font-size: 14px;
                }
              }
            }

            .modal-header {
              display       : inline-block;
              text-align    : center;
              border        : none;
              padding-bottom: 0;
              margin-top    : 30px;
            }

            .modal-footer {
              display       : inline-block;
              padding       : 0;
              border        : none;
              padding-bottom: 30px;
            }

            .cetas-close {
              position: absolute;
              top     : 20px;
              right   : 20px;
            }
          }
        }
      }

      // dashboard tiles
      .tile {
        background      : #fff;
        background-color: #FFFFFF;
        box-shadow      : 0 0 4px 4px rgba(221, 221, 221, 0.5);
        padding         : 30px;
        margin          : 15px 0;

        .tile-heading {
          color      : $primary-text;
          font-size  : 24px;
          font-weight: 700;
        }

        a.red {
          color: $primary;
        }

        h3 {
          font-size     : 21px;
          font-weight   : bold;
          letter-spacing: 0;
          line-height   : 28px;
        }
      }

      .tile-button {
        button {
          margin-top: 80px;
        }
      }

      // action button container --- fixed button at bottom start here
      .form-box {
        padding-bottom: 100px;
      }

      .action-btn-container {
        padding   : 10px 0;
        position  : fixed;
        bottom    : 50px;
        left      : 0;
        background: white;
        z-index   : 1;
        width     : 100%;
        box-shadow: 3px 0 4px 1px rgba(174, 174, 174, 0.5);

        button {
          margin-right: 10px;
        }
      }

      .success-box {
        margin: 0 auto;
      }

      .menu-box {
        input {
          width: 100%;
        }
      }

      .delete-modal-body {
        .delete-check-box {
          width: 10%;
          float: left;
        }

        .form-group {
          width: 100%;
          float: left;
        }

        .input-box {
          float: left;
          width: 90%;
        }
      }

      // table styling start here
      .emplist-box .table-infobox {
        /*by shreyas*/
        width: 100% !important;
      }

      .employee-actions {
        position: relative;

        .table-infobox {
          width   : 80%;
          float   : left;
          position: relative;

          .filter-box {
            width   : 69%;
            position: absolute;
            top     : -23px;
            right   : 0;

            .box {
              /*by shreyas*/
              float       : right;
              margin-right: 22%;
            }
          }

          .input-group {
            // / float: left !important; /
            width      : 250px !important;
            margin-left: 30px;
            display    : inline-block;
            display    : inline-flex;
          }

          input,
          select {
            border       : none;
            border-bottom: solid 1px $primary;
            border-radius: 0;
            height       : 50px;
            font-size    : 14px;
            box-shadow   : none;
          }

          .list-count {
            color    : #ADADAD;
            font-size: 14px;
            width    : 30%;
            float    : left;
          }

          .input-group-text {
            background   : none;
            border       : none;
            border-bottom: solid 1px $primary;
            border-radius: 0;
            color        : $placeholder;
          }
        }

        .sort-roll {
          width   : 12%;
          float   : right;
          position: absolute;
          top     : -10px;
          right   : 0px;

          select {
            font-size: 14px;
          }
        }
      }

      // .css-g1d714-ValueContainer{
      //     height: 20px;
      //   }
      //   .css-g1d714-ValueContainer .css-1uccc91-singleValue {
      //     top: 145%;
      //   }
      //   .css-yk16xz-control {
      //     height: 25px;
      //   }

      //   .css-9ebb9a {
      //     position: relative;
      //     z-index: 9999;
      //     left: 210px;
      //   }
    }

    //styles for landing page
    .landing-wrapper {
      margin-top: 70px;

      .navbar-nav {
        margin-left: 60%;
      }

      section {
        padding-top: 70px;
      }
    }

    .about-section {
      .about-imgbox {
        img {
          width        : 80%;
          border-radius: 20% 0 0 0;
          padding      : 20px 0;
        }
      }

      .about-infobox {
        h3 {
          padding: 20px 0;
        }
      }
    }

    section.services-section {
      padding : 20px;
      position: relative;

      .col-md-4 {
        padding: 0;

        .service-box {
          border-right : solid 1px #ececec;
          height       : 300px;
          width        : auto;
          background   : white;
          border-bottom: solid 1px #ececec;
          transform    : all 0.5s;
          text-align   : center;

          &:nth-child(3),
          &:nth-child(6) {
            border-right: none !important;
          }

          &:hover {
            box-shadow: 0px 0px 10px 1px #ececec;
            z-index   : 1;
            position  : relative;
          }

          a.service-link {
            margin: 0 auto;
            width : 90%;
          }

          .link-box {
            transform: translate(0%, 50%);
            width    : 34%;
            margin   : 0 auto;

            span {
              display   : inline-block;
              width     : 100%;
              margin-top: 10px;

              img {
                &:hover path {
                  fill: #72a200;
                }
              }
            }
          }
        }
      }

      .service-heading {
        padding: 30px;

        img {
          float       : left;
          height      : 60px;
          margin-right: 10px;
        }

        h3 {
          color     : $primary;
          font-size : 24px;
          margin-top: 15px;
        }

      }

      .v-top {
        margin-top: -300px;
      }
    }

    .service-open.left {
      height    : 600px;
      background: #dfdfdf;
      // width: 33.5%;
      width     : 100%;
      position  : absolute;

      box-shadow: 0px 1px 7px 1px #3333331f;
      // left: 18px;
      z-index   : 2;

      ul.service-points {
        list-style-type: none;
        padding        : 0 30px;
      }

      span.subpoint-heading {
        font-weight: 700;
        color      : $primary-text;
        padding    : 0 30px;
      }

      .close {
        float      : right;
        font-size  : 1.5rem;
        font-weight: 700;
        line-height: 1;
        color      : #000;
        text-shadow: 0 1px 0 #fff;
        opacity    : .5;
        margin     : 10px;
      }
    }


    .service-open.middle {
      height    : 600px;
      background: #dfdfdf;
      // width: 33.5%;
      width     : 100%;
      position  : absolute;

      box-shadow: 0px 1px 7px 1px #3333331f;

      z-index: 2;

      ul.service-points {
        list-style-type: none;
        padding        : 0 30px;
      }

      span.subpoint-heading {
        font-weight: 700;
        color      : $primary-text;
        padding    : 0 30px;
      }

      .close {
        float      : right;
        font-size  : 1.5rem;
        font-weight: 700;
        line-height: 1;
        color      : #000;
        text-shadow: 0 1px 0 #fff;
        opacity    : .5;
        margin     : 10px;
      }
    }

    .service-open.right {
      height    : 600px;
      background: #dfdfdf;
      // width: 33.5%;
      width     : 100%;
      position  : absolute;
      box-shadow: 0px 1px 7px 1px #3333331f;
      // right: 18px;
      z-index   : 2;

      ul.service-points {
        list-style-type: none;
        padding        : 0 30px;
      }

      span.subpoint-heading {
        font-weight: 700;
        color      : $primary-text;
        padding    : 0 30px;
      }

      .close {
        float      : right;
        font-size  : 1.5rem;
        font-weight: 700;
        line-height: 1;
        color      : #000;
        text-shadow: 0 1px 0 #fff;
        opacity    : .5;
        margin     : 10px;
      }
    }



    section.contact-us {
      background           : url("../Assets/Images/contact-back.png");
      background-repeat    : no-repeat;
      background-attachment: fixed;
      background-size      : cover;
      height               : 600px;

      input,
      textarea {
        border: solid 1px #9A0800 !important;
      }

      .address-box {
        background: #a90007b5;
        padding   : 30px;
        color     : #fff;

        img {
          height      : 18px;
          margin-right: 10px;
        }

        a {
          color          : #fff;
          text-decoration: underline;
        }
      }
    }


    .dropdown-menu.show {
      background   : #Fff;
      border       : none;
      box-shadow   : 0px 0px 1px 1px #DDDDDD;
      border-radius: 0;

      a {
        border-bottom: solid 1px $seperator;

        &:hover {
          background: $primary;
          color     : $white;
        }

        &:last-child {
          border: none;
        }
      }
    }

    // for tabs

    .graph-tabs {
      margin-top: 30px;

      button {
        color: $primary;

        &:hover {
          color: $primary
        }
      }

      .nav-tabs {
        border       : none;
        border-bottom: solid 1px #dadada;
      }

      .nav-link {
        &:hover {
          border: none;

          &:hover,
          &:focus {
            border       : none;
            border-bottom: solid 3px $primary;
          }
        }

        &:focus {
          border: none;
        }
      }

      .nav-link.active {
        border       : none;
        border-bottom: solid 3px $primary;

        &:hover,
        &:focus {
          border       : none;
          border-bottom: solid 3px $primary;
        }

      }


      li {
        button.active {
          color: $primary !important;

          img {
            height: 20px;
          }
        }


      }

      .tab-content {
        padding-top: 30px;

        img {
          width: 100%;
        }
      }
    }