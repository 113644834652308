/* @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap'); */

:root {
    --brand: #A90007;
    --dark: #092032;
    --body: #516171;
    --inputborder: #b6b7b8;
    --border: rgba(0,0,0,0.08);
    --shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    --fontcolor:#919da9;
    --pagecolor:#e9ecef;
    
}

body {
    // font-family: "Barlow", sans-serif !important;
    color: var(--body);
    line-height: 1.7;
}

h1,h2,h3,h4,h5,h6,
.display-1,.display-2,.display-3,.display-4 {
    font-weight: 700;
color: var(--dark) ;
}

.bg-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* img {
    width: 100%;
} */

a {
    color: var(--dark);
    transition: all 0.4s ease;
    font-weight: 500;
}

a:hover {
    color: var(--brand) !important;
}

section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.text-brand {
    color: var(--brand) !important;
}


.hero-slider .owl-prev,
.hero-slider .owl-next{
    background-color: rgba(255, 255, 255, 0.3) !important;
    width: 60px !important;
    height: 60px !important;
    display: block;
    display: grid;
    place-items: center;
    color: #fff !important;
    border-radius: 100px;
    line-height: 0;
    border-radius: 100px !important;
    position: absolute;
    top: 50%;
    font-weight: 600 !important;
    font-size: 12px !important;
    transition: all 0.4s ease;
    margin-top: -30px !important;
}

.owl-prev {
    left: 0;
}
.owl-next {
    right: 0;
}

.hero-slider .owl-prev:hover,
.hero-slider .owl-next:hover {
    background-color: var(--brand) !important; 
}

.owl-dot.active span{
    background-color: var(--brand) !important;
}


 /* slide */
 .slide {
     min-height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
     position: relative;
}

.slide1 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(./Assets/Images/project1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.slide2 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(./Assets/Images/bg_banner1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide3 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(./Assets/Images/project2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide .display-3 {
    text-transform: uppercase;
    color: #fff;
}

/* navbar */
.top-nav {
    background-color: var(--brand);
    color: #fff;
    // /* padding-top: 5px;
    // padding-bottom: 5px; */
   //  margin-top: -12vh !important;
   // width: 100% !important;
    height: 41px !important;
   
}

.top-nav .email1 {
    /* background-color: orange !important; */
     width: 51.6% !important;
     float: left;
     margin-left: 6.5% !important;
    margin-top: .4% !important;
    height: 30px !important;
    //  margin-top: .4%;
    //  margin-bottom: .3%;
}

.top-nav .social {
    /* background-color: green !important; */
     width: 33% !important;
     float: left !important;
     text-align: right !important;
    //  padding-top: 13px!important;
    //  margin-top: -7px !important;
     height: 30px !important;
   
     margin-top: .4%;
     margin-bottom: .3%;
   
}

.top-nav .cont {

     width: '100%' !important;
     background-color: aqua !important;
}

.top-nav p {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;
    vertical-align: middle;
}

.top-nav span,
.top-nav i {
    vertical-align: middle;
}

.navbar {
     box-shadow: var(--shadow) !important; 
    // margin-top: -12vh !important;
      
}

.navbar img { position: absolute !important; margin-left: 2%; }

.banner { 
    // background:url(./Assets/Images/herobg.png);
    background-image: url(./Assets/Images/herobg.png);
     background-size: cover;
    // background-position: center;
     background-repeat: no-repeat;
    height: 80vh;
    width: 100%;

 }

 .searchbanner{ 
    // background:url(./Assets/Images/herobg.png);
    background-image: url(./Assets/Images/bg.jpg);
     background-size: cover;
    // background-position: center;
    background-repeat: no-repeat;
    height: 13vh;
    padding-top: 40PX;
    width: 100% !important;
    padding-left: 4%;
    padding-right: 4%;
    // background-color: #A90007;
    
 }

 .searchbanner .searchbox{ 
    //height: 13vh;
    width: 40%;
    text-align: left;
   // background-color: orange;
    display:inline-block;
    
 }

 .searchbanner .searchbutton{ 
    //background-color: green;
   // height: 13vh;
    width: 30%;
    text-align: left;
   // position: relative;
    display:inline-block;
    //position: absolute !important;

 }
 .searchbanner .talktoexpert{ 
    //background-color: pink;
    //height: 13vh;
    width: 30%;
    text-align: right;
    display:inline-block;
    //position: absolute !important;

 }


 .service_insights{ 
     background-size: cover;
    // background-position: center;
     background-repeat: no-repeat;
    //height: 13vh;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    background-color:var(--brand);
    color: #fff;
    font-weight: bolder;
    font-size: 30px;

 }



.social-icons a {
    width: 28px;
    height: 28px;
    display: inline-flex;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.25);
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    
}
.social-icons a
{
  margin-right: 5px !important;
  
}
.social-icons a:hover {
    background-color: #fff;
    color: var(--brand);
}
.conditions-section{
    margin: 20px 0;
}
.conditions-section a{
   color: #fff;
   margin: 0 10px;
}


.navbar .content {
    // padding-left:70px !important;
    // padding-right: 140px !important;
    padding-top: 20px !important;
    min-height: 80px !important;
    width: 100%;
}


.navbar .navbar-nav .nav-link {
    color: var(--dark) !important;
     background-color: transparent !important;
     font-size: 15px !important;
     font-weight: 600 !important;
     min-width: 25px !important;
     margin-top: 13px !important;
   
}


.navbar .navbar-nav .nav-item  {
  //  background-color: #DC3545 !important;
    // width: 70% !important;
     float: right !important;
    padding-top: 7px;
    padding-bottom: 7px;
   
}

.navbar .navbar-nav .emailimg {
     //background-color: #DC3545 !important;
     width: 15px;
     margin-left: 25px;
     height: 20px;
     background-image:url(./Assets/Images/email.svg);
     background-repeat: no-repeat;
      margin-top: 34px;
    //   width: 70% !important;
    //    float: right !important;
    //   padding-top: 7px;
    //   padding-bottom: 7px;
     
  }
  


  .navbar .nav-signup  {
   float: right !important;
   padding-top: 7px;
   padding-bottom: 7px;
   text-align: right !important;
   margin-right: 2%;
   color: var(--brand) !important;
//    background-color:orange !important;
   background-image:url(./Assets/Images/user-bold.svg);
   background-repeat: no-repeat;
   background-size: 12%;
   background-position: left;
   padding-left: 24px;
   font-size: 14px;
     
     
}

.navbar .navbar-signup .link {
     color: var(--brand) !important;
    // background-color:olive !important;
     font-size: 18px !important;
    font-weight: bold !important;
     min-width: 25px !important;
     margin-top: 13px !important;

   
}


.navbar .navbar-nav .nav-link:hover {
    color: var(--brand) !important;
}

.navbar .navbar-nav .nav-link.active {
    color: var(--brand) !important;
}

.navbar .navbar-nav .nav-social  {
    // background-color:#004164 !important;
    // float: right !important;
   
//    width: 100% !important;

font-size: 15px !important;
     font-weight: 600 !important;
     min-width: 25px !important;
     margin-top: 26px !important;
}
.navbar .navbar-nav .nav-social .bxl-linkedin{
    color: #fafafa;
    background: #0077b5;
    padding: 7px;
    border-radius: 2px;
  }
  .navbar.linkedin{
    background: #0077b5;
  }
  .navbar .navbar-nav .nav-social .bxl-linkedin:hover{
    color: #fafafa;
    background: #004164;
  }

.navbar-brand {
    font-size: 28px;
    font-weight: 700;
}

.navbar-brand .dot {
    color: var(--brand);
}

.btn {
    padding: 8px 26px;
}

.btn-brand {
    border-color: var(--brand) !important;
    background-color: var(--brand) !important;
    color: #fff !important;
}

.btn-brand:hover {
    background-color: #A90007 !important;
    border-color: #A90007 !important;
    color: #fff !important;
}

.loginheader
{
    text-align: center;
  //  background-color: #ccc;    
    width: 45%;
    display: inline-block;
   // margin-top: -50px;
}

.loginheader img
{
    height: 70px;
    width: 70px;
}



.loginheader p {
    max-width: 100%;
    margin-top: 10px;
    font-size: 14px;
    color:#072D4F !important;
}


.login
{
    // text-align: center;
    background-color: #fff;    
    width: 42%;
    display: inline-block;
    // padding: 2%;
   margin-top: -70px;
   height: auto !important;
   min-height: 200px !important;
}

.login button
{
    
    width: 100%;
  //  background-color: #072D4F !important;
    background-color: #0C4272 !important;
    color: #fff;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px;
}

.login button:hover
{
    
    width: 100%;
    background-color: #072D4F !important;
    color: #949393;
   // font-weight: bold;
}

.login h4{
    color:  #072D4F !important;
    font-weight: 500 !important;   
}

.login h6{
    // color:  #072D4F !important;
    font-weight: 400 !important;
    background-color: rgb(247, 240, 223);
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 13px;
    border-radius: 5px;
   
}
.login h5{
    color:  #4f4e4e !important;
    font-weight: 500 !important;
    font-size: 16px;
    margin-top: 10px;
   
}
.login social{
    color:  #4f4e4e !important;
    font-weight: 500 !important;
    font-size: 16px;
    margin-top: 10px;
   
}

.login .bxl-linkedin{
    color: #fafafa;
    background: #0077b5;
    padding: 12px;
    border-radius: 4px;
    margin-left: 5px;   
  }
  .login .bxl-linkedin:hover{
    color: #fafafa;
    background: #004164;
  }

  .login .bxl-facebook{
    color: #fafafa;
    background: #0077b5;
    padding: 12px;
    border-radius: 4px;
  }
  .login .bxl-facebook:hover{
    color: #fafafa;
    background: #004164;
  }

  .login .bxl-twitter{
    color: #fafafa;
    background: #2DBFFA;
    padding: 12px;
    border-radius: 4px;
    margin-left: 5px;
  }
  .login .bxl-twitter:hover{
    color: #fafafa;
    background: #169FD6;
  }

  .login .bxl-google{
    color: #fafafa;
    background: #f45353;
    padding: 12px;
    border-radius: 4px;
    margin-left: 5px;
  }
  .login .bxl-google:hover{
    color: #fafafa;
    background: #e32727;
  }


.login p{
    color:var(--fontcolor) !important;
    font-weight: 400 !important;
    font-size: 14px !important;  
    
}

.login forgotpass{
    color:orange !important;
    font-weight: 400 !important;
    font-size: 14px !important;  
    text-align: right !important;
    float: right !important;
}

.login .inputboxsocial{
    width: 100%;
    height: 40px !important;
  
}

.signuplink{
    width: 100%;
    margin-top: 10px !important;
    font-size: 14px !important;
}

.error{
    color:red !important;
    font-weight: 500 !important;
    font-size: 12px !important;  
    text-align: left !important;
    margin-top: -5px !important;
    height: 15px !important;
  
}

.dasbox
{
    width: 25%;
    margin-top: 20px;
    
}

.dasbox_kol
{
    width: 40%;    
}

.dasbox_kol .dppep
{
    width: 55%; 
  //  background-color: orange;
    display: inline-block;
    margin-left: 40%;
    margin-top: -7px;

    

}


.dasbox_kolh2
{
    font-size: 20px;
    display: inline-block;
    //background-color: green !important;
    margin-left: 15%;
    color: #01345C;
    padding-top:8px;
    padding-bottom: 8px;
    font-weight: 500;
 }

.dasbox_kolp
{
    font-size: 13px; 
    color: #000;
    font-weight: 400;  
   // background-color: #0077b5 !important;
}
.tc{   
    
    text-align: left !important;
   // padding-top: 5px;
    //padding-bottom: 5px;
    width: 100%;
  
}

.tc chbox{
   
    
    // background-color: orange !important;
    text-align: left !important;
    display: inline-block !important;
    width: 6% !important;
    float:left !important;
    height: 20px !important;
    padding-top: 0px !important;
    margin-top: -5px !important;

  
}
.tc label{
   
    
   // background-color:gray !important;
    text-align: left !important;
    display: inline-block !important;
    width: 90% !important;

  
}
.tc error{
    //background-color: olive !important;
    
    color:red !important;
    font-weight: 500 !important;
    font-size: 12px !important;  
    text-align: left !important;
   // margin-top: -15px !important;
    height: 15px !important;
    width:100%;
 
   
 }
 

.login label
{
    text-align: left !important;
    display: inline-block;
    // color: #A90007 !important;
   // background-color: #004164;
    width: 100%; 
    height: 20px !important;  
    float: left !important;
}

.login .inputbox
{
    width: 100%;
    height: 70px !important;
  
}
.login input
{
    text-align: left !important;
    display: inline-block;
    // color: #A90007 !important;
   // background-color: #004164;
    width: 100%;
    height: 37px !important;
   border: solid 1px var(--inputborder) !important;
   font-size: 14px !important;
}



.quoteform
{
    // text-align: center;
    background-color: #ccc;    
    width: 95%;
    display: inline-block;
    // padding: 2%;
   margin-top: -70px;
   height: auto !important;
   min-height: 200px !important;
}

.quoteform h3
{
    color: orange;
}

.quoteformradio
{
     text-align: left;
      background-color: #004164;
}


.quoteform label
{
    text-align: left !important;
    display: inline-block;
    // color: #A90007 !important;
   // background-color: #004164;
    width: 100%; 
    height: 20px !important;  
    float: left !important;
}

.quoteform .inputbox
{
    width: 100%;
    height: 70px !important;
  
}
.quoteform input
{
    text-align: left !important;
    display: inline-block;
    // color: #A90007 !important;
   // background-color: #004164;
    width: 100%;
    height: 37px !important;
   border: solid 1px var(--inputborder) !important;
   font-size: 14px !important;
}




.preclient{ 
   // height: 7vh;
    //padding-top: 40PX;
    //width: 400px!important;
    //padding-left: 4%;
    //padding-right: 4%;
   // float: left !important;
   width: 100%;
  // background-color: orange !important;
    
 }

 .preclient .searchbox{ 
    //height: 13vh;
    width: auto;
    min-width: 190px;

    text-align: left;
    display:inline-block;
    margin-top: 2px;
    
 }

 .searchbutton{ 
    //background-color: green;
   // height: 13vh;
    width: 30%;
    text-align: left;
   // position: relative;
    display:inline-block;
    //position: absolute !important;
    // background-color: #0C4272 !important;

 }

 .search_button
{
    
  //  width: 100%;
  //  background-color: #072D4F !important;
    background-color: #0C4272 !important;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    min-width: 110px;
    font-weight: 400;
    font-size: 14px;
    margin-left: 3px;
}

.mandatory
{
    color: red !important;
}





.search_button:hover
{
    color: #fff !important;
    background-color: #072D4F !important;
    //font-weight: bold;
}

 .search_button{ 
    //background-color: green;
   // height: 13vh;
    width: 30%;
    text-align: left;
   // position: relative;
    display:inline-block;
    //position: absolute !important;
     background-color: #0C4272 !important;

 }
//  backgroundColor:'#0C4272',color:'#fff', paddingLeft:'20px',paddingRight:'30px',paddingTop:'10px',paddingBottom:'10px', fontSize:'12px'
  .logoutbox{ 
    //background-color: green !important;
   // height: 13vh;
 //   width: 200px;
    text-align: left;
   // position: relative;
    display:inline-block;
    //position: absolute !important;
    margin-top: -10px;
   //  background-color: orange !important;

 }
 .user_signout
 {
 
 //background-image:url(./Assets/Images/user.jpg);
 //background-repeat: no-repeat;
 //background-image: 30%;
    font-size: 16px;
    font-weight: normal !important;
  //  background-color: #0077b5 !important;
    display:inline-block;
    width: auto;
    min-width: 50px;
   //  height: 37px;
    //  padding-top: 7px;
    //  padding-bottom:7px;
     color: #000;
     //margin-top: 8px;
    //  padding-left: 5px;
      padding-right: 15px;

 }
.button_signout
{
    display:inline-block;
    background-color:#DC3545 !important;
    border-color: #DC3545 !important;
   
   // background-color:var(--brand) !important;
  //  background-color: #072D4F !important;
   // background-color: #0C4272 !important;
    color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 5px;
    font-size: 14px !important;
    font-weight:300 !important;
    padding-left: 15px;
    padding-right: 15px;
    height: 37px;
   // margin-top: -20px !important;

}

.button_signout:hover
{
    background-color: #eb484d !important;
    border-color: #ee4e54 !important;
    //font-weight: bold !important;
   
    //background-color: rgb(255, 0, 0) !important;
   
}




.intro {margin-bottom: 36px;
text-align: center;}

.intro p {
    max-width: 900px;
}
.intro h4{
    color: var(--brand);
    font-weight: 400;
    text-transform: uppercase;
}

/*.intro h1 {
    margin-top: 15px;
    margin-bottom: 15px;
}*/

.info-box {
    align-items: center;
    display: flex;
}

.info-box img {
    width: 90px;
}


#milestone {
    background: linear-gradient(rgba(255, 77, 41, 0.85), rgba(255, 77, 41, 0.85)), url(./Assets/Images/bg_banner1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#milestone h1,
#milestone p {
    color: #fff;
}

.service {
    padding: 32px;
    background-color: #fff;
    box-shadow: var(--shadow);
}

.service h3 {
    margin-top: 15px;
    margin-bottom: 14px;
}


.project {
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.project h6 {
    font-weight: 400;
}

.project h6::before {
    content: "";
    height: 2px;
    width: 30px;
    display: inline-block;
    background: var(--brand);
    vertical-align: middle;
    margin-right: 10px;
}


.project .overlay {
    width: 100%;
    height: 220px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 76, 41, 0) 0%, var(--dark) 100%);
}

.project .content {
    position: absolute;
    left: 10%;
    bottom: 10%;
    height: 220px;
}

.project h2,
.project h6 {
    color: #fff;
}

.team-member {
    text-align: center;
}

.team-member .image{
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.team-member .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -10%;
    background-color: rgba(255, 77, 41, 0.7);
    opacity: 0;
    transition: all 0.4s ease;
}

.team-member h5 {
    margin-top: 16px;
    margin-bottom: 4px;
}

.team-member .social-icons {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    transition: all 0.4s ease;
}

.team-member .social-icons a {
    width: 40px;
    height: 40px;
}

.team-member:hover .social-icons {
    top: 50%;
    opacity: 1;
}

.team-member:hover .overlay {
    top: 0%;
    opacity: 1;
}

#reviews {

    background: linear-gradient(-90deg, rgba(8, 32, 50, 0.8), rgba(8, 32, 50, 0.8)), url(./Assets/Images/bg_banner1.jpg), #082032;;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.review {
    text-align: center;
    z-index: 2;
    position: relative;
    margin: 15px;
    max-width: 768px;
    margin: auto;
}

.review .bxs-quote-alt-left {
    font-size: 120px;
    position: absolute;
    opacity: 0.1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.review img {
    width: 80px !important;
    height: 80px;
    border-radius: 100px;
    margin: auto;
}

.review h5 {
    margin-top: 16px;
    margin-bottom: 4px;
    color: #fff;
}

.review h3 {
    margin-top: 26px;
    margin-bottom: 26px;
    font-size: 22px;
    color: #fff;
    font-weight: 400;
    line-height: 1.7;
}

.review small {
    color: var(--brand);
}

.review .stars {
    color: var(--brand);
}

.blog-post {
    position: relative;
    background-color: #fff;
    box-shadow: var(--shadow);
}

.blog-post .content {
    padding: 32px;
}

.blog-post a {
    
    background-color: var(--brand);
    padding: 2px 12px;
    border-radius: 100px;
    text-decoration: none;
    color: #fff;
}

.blog-post a:hover {
       
    color: #fff !important;
    font-weight: bolder !important;
}

.blog-post h5 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.blog-post small {
    text-transform: uppercase;
    color: var(--brand);
    text-decoration: underline;
}




footer {
   // background:#000;
    background-size: cover;
   background-position: center;
    background-repeat: no-repeat;
    margin-top: -20px !important;
}

.footer-copyright {
    padding-top: 15px;
    padding-bottom: 17px;
     text-align: left;
     float: left;
    width: 50%;
    padding-left: 3%;
    background-color: #000;
    font-size: 12px !important;
}
.footer-tc {
    font-size: 12px !important;
    padding-right:3% ;
    padding-top: 15px;
    padding-bottom: 17px;
    text-align: right;
    float: right;
    width: 50%;
    font-weight: normal !important;
    background-color: #000;
}

footer .footer-top {
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 20px;
    padding-top: 20px;
}

footer .navbar-brand {
    color: #fff;
}

footer span {
    color: #8a8989;
    font-size: 14px;
    
}

footer a {
    width: 50px;
    
    font-weight: normal;
     font-size: 14px;
    margin-left: 7px;
    margin-right: 7px;
    color: #fff !important;
}

.loader {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    z-index: 99999;
    position: fixed;
    left: 0;
    right: 0;
}


input.form-control {
    border-color: transparent;
    height: 44px;
}

.form-control {
    background-color: rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.04);;
}

.form-control:focus {
    box-shadow: none;
    border-color: var(--brand);
}
.markImg
{
    margin-right: auto !important;
    margin-left: auto !important;
    padding-top: 50px !important;
    display: block !important;
    max-width: 50px !important; max-height: 100px !important;text-decoration: none !important;
}
 
::-webkit-scrollbar {
  width: 6px;
  cursor: pointer !important;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
  cursor: pointer !important;
}
 

::-webkit-scrollbar-thumb {
  background: #DC3545; 
  border-radius: 5px;
  cursor: pointer !important;
}


::-webkit-scrollbar-thumb:hover {
  background: #DC3545; 
  cursor: pointer !important;
} 

#bckrnd
{
  background-image: url(./Assets/Images/c2.jpg);
  min-height:300px;
}
#lglogos
{
  width: 150px !important; margin-left: auto !important; margin-right: auto !important; display: block !important;
}
#mlct
{
  font-size: 13px !important;
}
.h1d
{
  font-size: 30px !important;
}
