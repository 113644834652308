body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

   /* background-color: #F9F9F9 !important; */

   /* background-color: orange !important; */
   
   background-color: #fff !important;
   padding-top: 6vh !important;;
   /* font-family:Broadway !important ; */
   /* font-family: "Georgia", monospace; */
   /* font-family: "Barlow", sans-serif !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

    /* font-family:Broadway !important ; */
}
